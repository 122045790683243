import {
  Card,
  ECardBorderRadiusSize,
  ECardMediaSize,
  ETextStyleVariant,
  GeneralArrowRightIcon,
  Loader,
  Text,
} from '@outdoorsyco/bonfire';
import cn from 'classnames';
import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  EDestinationCardClassName,
  TDestinationCardThumbnail,
} from '@/constants/home/signatureDestinationsAndParks';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import css from './DestinationCard.module.css';
import { DestinationCardThumbnail } from './DestinationCardThumbnail';

interface IDestinationCard {
  imageSrc: string | StaticImageData;
  imageSrcMobile: string | StaticImageData;
  imageSizes?: string;
  name: string;
  locationName?: string;
  url?: string;
  thumbnails?: TDestinationCardThumbnail[];
  isMobile?: boolean;
  cardClassName?: EDestinationCardClassName | string;
  isLoading?: boolean;
  isSignatureDestination?: boolean;
}

export const DestinationCard = ({
  imageSrc,
  imageSrcMobile,
  imageSizes,
  name,
  locationName,
  url,
  thumbnails,
  isMobile = false,
  cardClassName = '',
  isLoading = false,
  isSignatureDestination = false,
}: IDestinationCard) => {
  const { isAboveDesktop } = useBreakpoint();

  const cardContent = (
    <Card borderRadiusSize={ECardBorderRadiusSize.Large} withBoxShadow={false}>
      <Card.Media
        className={cn(
          'overflow-clip !h-[345px] rounded-2xl group',
          isLoading ? '' : css.cardImageContainer,
          css[cardClassName] || '',
        )}
        size={ECardMediaSize.Large}>
        {isLoading ? (
          <div className="h-full bg-gray-200">
            <Loader className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        ) : (
          <>
            <Image
              className="object-cover object-left rounded-2xl lg:group-hover:scale-110 transition-transform duration-700 ease-in-out"
              src={isMobile ? imageSrcMobile : imageSrc}
              sizes={imageSizes}
              objectPosition="top"
              alt=""
              fill
            />

            <div className="absolute inset-0 opacity-50 bg-gradient-to-b from-transparent to-[rgba(0,0,0,0.4)] group-hover:opacity-100 transition-opacity duration-700 ease-in-out" />

            <div className="absolute z-10 w-full px-6 text-white pointer-events-none bottom-5">
              <Text
                className={cn('text-750', {
                  'pb-6': !locationName,
                  'pb-2': locationName,
                })}>
                {name}
              </Text>
              {locationName && (
                <div className="flex flex-row justify-between">
                  <Text className="font-bold" variant={ETextStyleVariant.SmallBold}>
                    {locationName}
                  </Text>
                  <GeneralArrowRightIcon />
                </div>
              )}
            </div>
          </>
        )}
      </Card.Media>
    </Card>
  );

  return (
    <div className="relative group">
      {isSignatureDestination ? (
        <a href={url || ''}>{cardContent}</a>
      ) : (
        <Link href={url || ''}>{cardContent}</Link>
      )}

      <div className="pt-2">
        {!!thumbnails?.length && (
          <Swiper
            slidesPerView={thumbnails.length > 3 ? 3.4 : thumbnails.length}
            spaceBetween={isAboveDesktop ? 10 : 0}
            watchSlidesProgress={true}
            preventInteractionOnTransition={true}
            allowTouchMove={true}
            noSwiping={false}
            noSwipingClass="swiper-no-swiping"
            resistance={true}
            resistanceRatio={0.85}>
            {thumbnails.map((thumbnail, index) => (
              <SwiperSlide key={index} className="swiper-no-swiping">
                <DestinationCardThumbnail {...thumbnail} sizes="100px" />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};
