import { StaticImageData } from 'next/image';
import { IntlShape } from 'react-intl';

import odnBayfield from '@/assets/home/odn/odn-bayfield.jpg';
import odnBayfieldInventory1 from '@/assets/home/odn/odn-bayfield-inventory1.jpg';
import odnBayfieldInventory2 from '@/assets/home/odn/odn-bayfield-inventory2.jpg';
import odnBayfieldInventory3 from '@/assets/home/odn/odn-bayfield-inventory3.jpg';
import odnBayfieldMobile from '@/assets/home/odn/odn-bayfield-mobile.jpg';
import odnHillCountry from '@/assets/home/odn/odn-hill-country.jpg';
import odnHillCountryInventory1 from '@/assets/home/odn/odn-hill-country-inventory1.jpg';
import odnHillCountryInventory2 from '@/assets/home/odn/odn-hill-country-inventory2.jpg';
import odnHillCountryInventory3 from '@/assets/home/odn/odn-hill-country-inventory3.jpg';
import odnHillCountryMobile from '@/assets/home/odn/odn-hill-country-mobile.jpg';
import odnYosemite from '@/assets/home/odn/odn-yosemite.jpg';
import odnYosemiteInventory1 from '@/assets/home/odn/odn-yosemite-inventory1.jpg';
import odnYosemiteInventory2 from '@/assets/home/odn/odn-yosemite-inventory2.jpg';
import odnYosemiteInventory3 from '@/assets/home/odn/odn-yosemite-inventory3.jpg';
import odnYosemiteMobile from '@/assets/home/odn/odn-yosemite-mobile.jpg';

enum EOdnCampgrounds {
  Yosemite,
  Bayfield,
  HillCountry,
}

export enum EThumbnailType {
  ThumbnailImage,
  ThumbnailText,
}

type TDestinationCardImageThumbnail = {
  type: EThumbnailType.ThumbnailImage;
  src: string | StaticImageData;
  url?: string;
};

type TDestinationCardTextThumbnail = {
  type: EThumbnailType.ThumbnailText;
  textContent: string;
  url?: string;
};

export type TDestinationCardThumbnail =
  | TDestinationCardImageThumbnail
  | TDestinationCardTextThumbnail;

export interface ISignatureDestination {
  imageSrc: string | StaticImageData;
  imageSrcMobile: string | StaticImageData;
  url: string;
  name: string;
  thumbnails: TDestinationCardThumbnail[];
  destinationDescription: string;
}

// hardcode 1 thumbnail url for staging since campsite rentals are limited there
const YOSEMITE_STAGING_THUMBNAIL_URL =
  'https://www.staging.outdoorsy.com/campsite-rental/bass-lake_ca/log-cabins_143593-listing';
const BAYFIELD_STAGING_THUMBNAIL_URL =
  'https://www.staging.outdoorsy.com/campsite-rental/bayfield_co/standard-luxury-tent_143117-listing';
const HILL_COUNTRY_STAGING_THUMBNAIL_URL =
  'https://www.staging.outdoorsy.com/campsite-rental/fredericksburg_tx/luxury-glamping-lodge-sleeps-4_143095-listing?address=stonewall%20tx';

const getDestinationUrl = (isProd: boolean, campground: EOdnCampgrounds) => {
  if (isProd) {
    if (campground === EOdnCampgrounds.Yosemite) {
      return 'https://www.outdoorsy.com/destinations/outdoorsy-yosemite';
    }

    if (campground === EOdnCampgrounds.Bayfield) {
      return 'https://www.outdoorsy.com/destinations/outdoorsy-bayfield';
    }

    if (campground === EOdnCampgrounds.HillCountry) {
      return 'https://www.outdoorsy.com/destinations/outdoorsy-hill-country';
    }
  }

  if (campground === EOdnCampgrounds.Yosemite) {
    return 'https://www.staging.outdoorsy.com/destinations/outdoorsy-yosemite';
  }

  if (campground === EOdnCampgrounds.Bayfield) {
    return 'https://www.staging.outdoorsy.com/destinations/outdoorsy-bayfield';
  }

  if (campground === EOdnCampgrounds.HillCountry) {
    return 'https://www.staging.outdoorsy.com/destinations/outdoorsy-hill-country';
  }

  return '';
};

export const signatureDestination = (intl: IntlShape, isProd: boolean): ISignatureDestination[] => {
  const yosemiteUrl = getDestinationUrl(isProd, EOdnCampgrounds.Yosemite);
  const bayfieldUrl = getDestinationUrl(isProd, EOdnCampgrounds.Bayfield);
  const hillCountryUrl = getDestinationUrl(isProd, EOdnCampgrounds.HillCountry);

  return [
    {
      imageSrc: odnYosemite,
      imageSrcMobile: odnYosemiteMobile,
      url: yosemiteUrl,
      name: intl.formatMessage({ defaultMessage: 'Yosemite, CA', id: 'zPAccX' }),
      thumbnails: [
        {
          src: odnYosemiteInventory1,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/bass-lake_ca/log-cabins_399738-listing'
            : YOSEMITE_STAGING_THUMBNAIL_URL,
        },
        {
          src: odnYosemiteInventory2,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/bass-lake_ca/premium-glamping-tent_404160-listing'
            : YOSEMITE_STAGING_THUMBNAIL_URL,
        },
        {
          src: odnYosemiteInventory3,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/bass-lake_ca/standard-fhu-back-in-25-off-mon-tue-wed-thru-12-31_399742-listing'
            : YOSEMITE_STAGING_THUMBNAIL_URL,
        },
        {
          textContent: intl.formatMessage({ defaultMessage: 'View all', id: 'pFK6bJ' }),
          type: EThumbnailType.ThumbnailText,
          url: yosemiteUrl,
        },
      ],
      destinationDescription: intl.formatMessage({
        defaultMessage:
          'Enjoy RV camping and the charm of rustic cabins on the shores of Bass Lake, minutes from Yosemite National Park, in California’s High Sierra.',
        id: 'jtLPlf',
      }),
    },
    {
      imageSrc: odnBayfield,
      imageSrcMobile: odnBayfieldMobile,
      url: bayfieldUrl,
      name: intl.formatMessage({ defaultMessage: 'Bayfield, CO', id: '3G07F9' }),
      thumbnails: [
        {
          src: odnBayfieldInventory1,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/bayfield_co/standard-luxury-tent-sleeps-2_399755-listing'
            : BAYFIELD_STAGING_THUMBNAIL_URL,
        },
        {
          src: odnBayfieldInventory2,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/bayfield_co/luxury-glamping-tent-sleeps-4-15-off-through-2024_420432-listing'
            : BAYFIELD_STAGING_THUMBNAIL_URL,
        },
        {
          src: odnBayfieldInventory3,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/bayfield_co/standard-fhu-back-in_399753-listing'
            : BAYFIELD_STAGING_THUMBNAIL_URL,
        },
        {
          textContent: intl.formatMessage({ defaultMessage: 'View all', id: 'pFK6bJ' }),
          type: EThumbnailType.ThumbnailText,
          url: bayfieldUrl,
        },
      ],
      destinationDescription: intl.formatMessage({
        defaultMessage:
          'Enjoy RV camping or the comfort of luxury glamping tents in a lush setting on the Pine River, between Durango and Pagosa Springs.',
        id: 'WiBorL',
      }),
    },
    {
      imageSrc: odnHillCountry,
      imageSrcMobile: odnHillCountryMobile,
      url: hillCountryUrl,
      name: intl.formatMessage({ defaultMessage: 'Hill Country, TX', id: 'RXZPm4' }),
      thumbnails: [
        {
          src: odnHillCountryInventory1,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/fredericksburg_tx/luxury-glamping-lodge-sleeps-4_399748-listing?sleeps%5Badults%5D=2&sleeps%5Bchildren%5D=2'
            : HILL_COUNTRY_STAGING_THUMBNAIL_URL,
        },
        {
          src: odnHillCountryInventory2,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/fredericksburg_tx/luxury-glamping-suite-sleeps-2_399747-listing?sleeps%5Badults%5D=2&sleeps%5Bchildren%5D=2'
            : HILL_COUNTRY_STAGING_THUMBNAIL_URL,
        },
        {
          src: odnHillCountryInventory3,
          type: EThumbnailType.ThumbnailImage,
          url: isProd
            ? 'https://www.outdoorsy.com/campsite-rental/stonewall_tx/luxury-glamping-lodge-ranch-pool-sleeps-4_429288-listing?sleeps%5Badults%5D=2&sleeps%5Bchildren%5D=2'
            : HILL_COUNTRY_STAGING_THUMBNAIL_URL,
        },
        {
          textContent: intl.formatMessage({ defaultMessage: 'View all', id: 'pFK6bJ' }),
          type: EThumbnailType.ThumbnailText,
          url: hillCountryUrl,
        },
      ],
      destinationDescription: intl.formatMessage({
        defaultMessage:
          'Enjoy a chill summer in the Texas Hill Country: a spring lake, sunrise hikes, starry nights, and luxurious air-conditioned glamping tents.',
        id: '9qAdsu',
      }),
    },
  ];
};

export const DESTINATION_CARDS_SLIDES_CONFIG = {
  DEFAULT: {
    PER_VIEW: 2.5,
    PER_GROUP: 2,
  },
  XXXL: {
    PER_VIEW: 3,
    PER_GROUP: 3,
  },
};

export const DESTINATION_CARDS_SLIDES_CONFIG_FOR_NATIONAL_PARKS = {
  DEFAULT: {
    PER_VIEW: 2.5,
    PER_GROUP: 2,
  },
  XXXL: {
    PER_VIEW: 3.4,
    PER_GROUP: 3.4,
  },
};

export enum EDestinationCardClassName {
  ODN = 'odnCard',
  NATIONAL_PARK = 'nationalParkCard',
}
